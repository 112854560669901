import { Component } from '@angular/core';
import { faMusic, faSearch, faUserAlt } from '@fortawesome/free-solid-svg-icons';

import SongsData from '../../assets/songdata/data.json';

@Component({
  selector: 'app-songs',
  templateUrl: './songs.component.html'
})
export class SongsComponent {

  faMusic = faMusic;
  faSearch = faSearch;
  faUserAlt = faUserAlt;

  constructor() { }

  private songs = SongsData;
  private searchText = '';

  private clearFilter() {
    this.searchText = '';
  }

}
