import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'songsfilter'
})

export class SongsfilterPipe implements PipeTransform {

    transform(items: any[], searchText: string): any[] {
        if (!items) { return []; }
        if (!searchText) { return items; }

        searchText = searchText.toLowerCase();

        return items.filter( item => {
           return item.title.toLowerCase().includes(searchText) || item.artist.toLowerCase().includes(searchText);
        });
    }
}
